@import 'theme';
@import '~bootstrap/scss/bootstrap';
@import 'components/steppers';
@import 'amostras-tecido/amostras-tecido';
@import 'subgrupos-tecido/subgrupos-tecido';
@import 'modelos/modelos';

html {
  position: relative;
  min-height: 100%;
}

body {
  // 	/* Margin bottom by footer height */
  margin-bottom: 60px;
  padding-top: 5rem;
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}

nav.navbar.bg-light {
  border: 1px solid #ddd;
  background-color: rgba(#fff, 0.9) !important;
}

button,
li.list-group-item-action {
  cursor: pointer;
}

@media print {
  body {
    padding-top: 15px;
  }

  .full-width-print {
    flex: 1;
    max-width: 100%;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 40px;
  line-height: 40px; /* Vertically center the text there */
  background-color: #f5f5f5;
  text-align: center;
}

/*
 * Off Canvas
 * --------------------------------------------------
 */
@media screen and (max-width: 767.99px) {
  .row-offcanvas {
    position: relative;
    transition: all 0.25s ease-out;
  }

  .row-offcanvas-right {
    right: 0;
  }

  .row-offcanvas-left {
    left: 0;
  }

  .row-offcanvas-right .sidebar-offcanvas {
    right: -100%; /* 12 columns */
  }

  .row-offcanvas-right.active .sidebar-offcanvas {
    right: -50%; /* 6 columns */
  }

  .row-offcanvas-left .sidebar-offcanvas {
    left: -100%; /* 12 columns */
  }

  .row-offcanvas-left.active .sidebar-offcanvas {
    left: -50%; /* 6 columns */
  }

  .row-offcanvas-right.active {
    right: 50%; /* 6 columns */
  }

  .row-offcanvas-left.active {
    left: 50%; /* 6 columns */
  }

  .sidebar-offcanvas {
    position: absolute;
    top: 0;
    width: 50%; /* 6 columns */
  }
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Pesquisar';
}

.img-preview {
  width: 100%;
}
.custom-file-label {
  text-align: left;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.imagem-container {
  display: inline-block;
  position: relative;
}

.imagem-container:hover .btnRemoverImagem {
  display: inline-block;
}
.btnRemoverImagem {
  position: absolute;
  right: 2%;
  top: 2%;
  display: none;
}

.listagem-scroll {
  max-height: 455px;
  overflow: auto;
  height: 100%;
}

.table-hover > tbody > tr:hover {
  cursor: pointer;
}

global-errors {
  position: fixed;
  top: 70px;
  right: 60px;
  z-index: 9999;
  max-width: 50%;
}

@media (max-width: 767.98px) {
  global-errors {
    max-width: 100%;
    left: 40px;
  }
}
