.modelo-svg {
	path[id^="Boneca"] {
		stroke: #F1ECE6 !important;
	}

	[id^="Boneca"] {
		[id^="Traço"] {
			fill: none;
		}

		[id^="Cor"] {
			fill: #EDE0D7 !important;
		}
	}

	rect[id^="Retângulo"] {
		stroke: #373435 !important;
		stroke-width: 1.5;
		fill: none !important;
	}

	[id^="Frente"] {
		[id^="Boneca"] {
			[id^="Traço"] {
				stroke: #CD9F6E;
				stroke-width: 2;
			}
		}
	}

	[id^="Costas"] {
		[id^="Boneca"] {
			[id^="Traço"] {
				stroke: #CD9F6E;
				stroke-width: 2;
			}
		}
	}

	[id^="Peça"] {
		path[class^="fil"] {
			fill: #DEDFDF;
			stroke: black;
			stroke-width: 2
		}
	}
}