
app-steppers {
	ul {
		list-style: none;
		padding: 8px 15px;
		display: flex;
		align-items: stretch;

		li {
			display: flex;
			align-items: center;
			flex-grow: 1;

			.step {
				flex-grow: 1;

				.step-number {
					background-color: rgba($gray-200, 0.6);
					color: rgba($gray-500, 0.6);
					border-radius: 30px;
					padding: 5px 6px 5px 9px;
					margin-right: 5px;
					position: relative;
					min-width: 25px;

					i.fa {
						position: absolute;
					}
				}

				.step-caption {
					color: rgba($gray-500, 0.6);
				}
			}

			.step-line {
				display: inline-block;
				flex-grow: 100;
				margin: 0 10px 3px 10px;
				height: 5px;
				background-color: rgba($gray-200, 0.6);
			}

			&.with-line {
				flex-grow: 100;
			}

			&.active {
				.step-line {
					background-color: darken(orange, 15%);
				}

				.step-number {
					background-color: darken(orange, 15%);
					color: #fff;
				}

				.step-caption {
					color: darken(orange, 15%);
				}
			}
		}
	}
}
